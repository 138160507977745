import React, { useEffect, useState } from "react";
import { Icon } from 'react-icons-kit';
import { ic_watch_later_outline } from 'react-icons-kit/md/ic_watch_later_outline'
import Buttons from "../components/Buttons";
import { useNavigate } from "react-router-dom";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";

const first = require('../assets/Character/Availability Heuristic.png')
const second = require('../assets/Character/Confirmation Bias.png')
const third = require('../assets/Character/Conscience.png')
const forth = require('../assets/Character/Fitness Function.png')
const fifth = require('../assets/Character/star_gray.png')

let text = `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H.Rackham.
The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H.Rackham.
The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H.Rackham.`

const Community = (props) => {

    const { chat_id, charactor_id } = props;
    const quests = [first, second, third, forth, fifth]
    const navigate = useNavigate();
    const [readmore, setReadMore] = useState(false)
    const defaultCharactor = useSelector(state => state.characotors.default_charactor)

    return (
        <div className="page_container">
            <div style={{ fontFamily: 'Dimbo', fontSize: '40px', textAlign: 'center', margin: '30px 0px' }}>
                Community Quest
            </div>
            <div className="rounded shadow border border-primary p-4 mb-5" >
                <div className="d-flex justify-content-between " >
                    <div className="d-flex align-items-center" >
                        <div>
                            <img src={require('../assets/Avatar.png')} style={{ width: '60px', height: '60px', objectFit: 'contain' }} />
                        </div>
                        <div>
                            <div className="fw-bold">Yogesh</div>
                            <div >@{"18_dhiraj"}</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div>
                            <Icon icon={ic_watch_later_outline} size={40} />
                        </div>
                        <div>
                            <div className="fw-bold">Best Time: 14 min 34 sec</div>
                            <div>@{"18_dhiraj"}</div>
                        </div></div>
                </div>
                <div className="my-5">
                    {quests.map((e, i) => {
                        return (<img key={i} src={e} alt="quest" style={{ maxWidth: '100px', width: '100px', objectFit: 'contain', marginRight: '40px' }} />)
                    })
                    }
                </div>
                <div className="text-center">
                    {text.length > 500 && !readmore ?
                        <div className="" >
                            {text.slice(0, 490)}<span onClick={() => setReadMore(true)} style={{ color: 'gray', cursor: 'pointer' }}> Read more...</span>
                        </div>
                        : <div >{text}<span onClick={() => setReadMore(false)} style={{ color: 'gray', cursor: 'pointer' }}> show less.</span></div>}
                    <div style={{ width: 'fit-content', margin: 'auto', marginTop: '20px' }}>
                        <Buttons title={"Play Now"} onClick={() => navigate('/chat/' + defaultCharactor)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Community