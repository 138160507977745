import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Battle = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [battleId, setBattleId] = useState(0)

    useEffect(() => {
        // console.log(location)
        if (location.pathname.includes('battle')) {
            let paths = location.pathname.split('/')
            if (paths[paths.length - 1].trim() == '') {
                navigate(location.pathname.slice(0, location.pathname.lastIndexOf("/")))
            }
            if (paths[paths.length - 1] == 'battle') {

            } else if (paths[paths.length - 2] == 'battle') {
                setBattleId(paths[paths.length - 1])
            }
        }
    }, [location])

    return (
        <div className="page_container" >
            <div style={{ textAlign: 'center' }}>
                <h1 style={{ fontFamily: 'Dimbo' }}>Battle -{battleId}</h1>
            </div>
        </div>
    )
}

export default Battle