import React, { useState, useEffect, useRef } from 'react'
import Buttons from './Buttons';
import { useElements, useStripe, PaymentElement } from '@stripe/react-stripe-js'

const PaymentModel = (props) => {

    const { showPopup, setShowPopup, onConfirm, clientSecret } = props

    const popupRef = useRef(null);
    const contentref = useRef(null)

    const [loading, setLoading] = useState(false)

    let stripe = useStripe();
    let elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setLoading(true)

        try {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: "http://localhost:3000/subscription",
                },
                redirect: "if_required",
            });
            // console.log(error)
            // console.log(paymentIntent)
            if (error) {
                if (error.type == 'validation_error') {
                    setLoading(false)
                    return
                }
                onConfirm({ success: false, msg: error.message })

            } else if (paymentIntent && paymentIntent.status === "succeeded") {
                // console.log(paymentIntent)
                onConfirm({ success: true, msg: "Payment successfull", data: paymentIntent })
            } else {
                onConfirm({ success: false, msg: error?.message || "Internal error occured." })
            }
            setLoading(false)
        } catch (error) {
            onConfirm({ success: false, msg: error?.message || "Internal error occured." })
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contentref.current && contentref.current.contains(event.target)) {
            } else {
                if (popupRef.current && popupRef.current.contains(event.target)) {
                    setShowPopup(false);
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            {showPopup && (
                <div
                    ref={popupRef}
                    className='popup_backdrop'
                >
                    <div ref={contentref} className='payment_model' >
                        <form onSubmit={handleSubmit} >
                            <div style={{ fontSize: '30px', fontFamily: 'Dimbo', marginBottom: '20px' }} >Payment Details</div>
                            <PaymentElement />
                            <div className='mt-4'>
                                <Buttons disabled={!stripe} title={'Submit'} onClick={handleSubmit} loading={loading} />
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div >
    )
}

export default PaymentModel