import React from "react";

const ToggleToxicHealthy = ({ activetype = 't', onChange = () => { } }) => {


    const changeActivetype = (type) => {
        onChange(type)
    }

    return (
        <div className="healthy_toxic_container my-5 flex-2" >
            <div onClick={() => changeActivetype('t')} className={`healthy_toxic_buttons ${activetype == 't' && "active"}`}>Toxic</div>
            <div onClick={() => changeActivetype('h')} className={`healthy_toxic_buttons ${activetype == 'h' && "active"}`}>Healthy</div>
        </div>
    )
}

export default ToggleToxicHealthy