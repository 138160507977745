import React, { useEffect, useState } from "react";

const VideoView = (props) => {

    const { video, onClose, showVideoView } = props
    const [isVideoPlaying, setIsVideoPlaying] = useState(false)
    const [time, setTime] = useState(null)

    const play_video = () => {

        if (isVideoPlaying) {
            document.getElementById(`sent_video${time}`).pause()
            setIsVideoPlaying(false)
        } else {
            document.getElementById(`sent_video${time}`).play()
            setIsVideoPlaying(true)
        }
    }

    const onVideoClose = () => {
        document.getElementById(`sent_video${time}`).pause();
        onClose()
    }

    useEffect(() => {
        let time = new Date().toLocaleTimeString()
        setTime(time);
        setIsVideoPlaying(true)
        return () => {
            setTime(time);
            let video = document.getElementById(`sent_video${time}`) || null
            if (video != null) {
                video.pause()
            }
            onClose()
        }
    }, [])

    return (
        <div>
            {showVideoView &&
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0, 0, 0, 0.9)',
                    zIndex: 102,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }} >
                    <div onClick={onVideoClose} style={{ cursor: 'pointer', position: 'absolute', top: 15, right: 15, backgroundColor: '#a0a0a0', width: '30px', height: '30px', borderRadius: '5px', display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <img src={require('../assets/cross.png')} style={{ width: 8, height: 8 }} />
                    </div>
                    <div style={{ width: '70%', height: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <video style={{ cursor: 'pointer', width: '90vw' }} id={`sent_video${time}`} onClick={play_video} autoPlay={true}>
                            <source alt="preview image" style={{ width: '130px', height: '130px', objectFit: 'cover' }} src={video} />
                            your browser doesn't support videos!
                        </video>
                    </div>
                </div>
            }
        </div >
    )
}
export default VideoView