import React, { useState } from 'react';
import logo from '../assets/logo.png';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import InputField from '../components/InputField';

const ForgotPassword = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({})
    const [_alert, setAlert] = useState({})

    const validateForm = () => {

        let error = {}

        if (email.trim() == "") {
            error.email = "Email is required!"
        } else {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            let valid = reg.test(email)

            if (!valid) {
                error.email = "Enter Valid Email!"
            }
        }

        if (Object.keys(error).length) {
            setErrors(error)
            return false
        }
        return true
    }

    const handleForgetPassword = () => {

        let validate = validateForm();
        if (validate) {
            navigate('/code', { state: { email } })
        }

    }


    return (
        <div>
            <div className='login'>
                {/* <img src={logo} width={200} /> */}
                <div className='text-header'>Forgot Password?</div>
                <div style={{ width: 300, marginBottom: 30, textAlign: 'center' }}>
                    Don’t worry! It happens. Please enter the email associated with your account.
                </div>
                {/* <div className="fields">
                    <span className="label" >Email Address</span>
                    <input
                        value={email}
                        className="input-field"
                        placeholder='Enter Email Address'
                        type='email'
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div> */}
                <InputField value={email} placeholder='Enter Email Address' type="email" onChange={setEmail} lable='Email Address' />
                {errors?.email && <div className="required-field" >{errors?.email}</div>}
                <Button name="Send Code" onClick={handleForgetPassword} />
            </div>
        </div>
    );
};

export default ForgotPassword;