import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import Api from '../services/Api';
import { useDispatch } from 'react-redux';
import { login } from '../actions/userAction';
import { Alert, Spinner } from 'react-bootstrap';
import InputField from '../components/InputField';
import { auth, firebase } from '../firebase'
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const Login = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [_alert, setAlert] = useState({});
    const [_helmet, setHelmet] = useState({ title: 'Nestheads/login', des: 'login' })
    const [fieldValues, setFieldVlues] = useState({})

    const [processing, setProcessing] = useState(false);
    const [errors, setErrors] = useState(null)
    const [cont, setCont] = useState(false);
    const location = useLocation();

    const { from, redirectmessage = null } = location?.state || { from: "" }

    console.log(from)

    const onChange = (key, val) => {
        // console.log(key, val)
        let _vals = { ...fieldValues }
        let _errors = { ...errors }
        _vals[key] = val
        delete _errors[key]
        if (val.trim() == "") {
            delete _vals[key]
        }
        setFieldVlues(_vals)
        setErrors(_errors)
    }

    const parseSLUser = (user, proID) => {
        var _user = {};
        // console.log(user)
        if (user) {
            var name = (user?.displayName || '').trim();
            var firstName = '', lastName = '';
            if (name.indexOf(' ') !== -1) {
                name = name.split(' ');
                firstName = name[0]; delete name[0];
                lastName = name.join(' ');
            } else {
                firstName = name;
            }

            var email = user?.email || null;
            var phone = user?.phoneNumber || null;
            var pData = user?.providerData && user?.providerData[0] || null;
            if (pData.providerId !== proID && user.providerData.length > 1) {
                user.providerData.map((e) => {
                    if (e.providerId == proID) {
                        pData = e
                        return
                    }
                })
            }
            // console.log(pData?.providerId, pData?.provider, user?.providerId)
            _user = {
                identifier: pData?.uid || user?.uid,
                provider: (pData?.providerId || pData?.provider) || user?.providerId,
                data: {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    phone: phone,
                    photourl: user?.photoURL || null,
                    emailverify: user?.emailVerified ? 1 : 0,
                }
            }
            if (_user.provider)
                _user.provider = _user.provider.toString().replace(".com", '');
        }

        return _user?.identifier ? _user : false;
    };

    const soclogin = async (fuser) => {
        // console.log(fuser.data);
        setProcessing(true)
        let res = await Api.post(`auth/loginwith/${fuser.provider}/${fuser.identifier}`, fuser.data);
        setProcessing(false)
        if (res.success) {
            if (res.data.action == 'complete_profile') {
                let usr = res.data?.user;
                let url = `auth/completeregistration/${usr?.provider?.name}/${usr?.provider?.identifier}/${usr?.provider?.id}`
                navigate('/complete-profile', { state: { userData: res.data.user, url } })
            } else {
                dispatch(login({ user: res.data.user, accessToken: res.data.accessToken }));
                Api.setToken(res.data.accessToken).then(() => {
                    if (from?.trim() != "") {
                        if (from == 'homepage') {
                            navigate('/', { state: { redirectmessage: redirectmessage } });
                        } else {
                            if (from[0] == '/') {
                                navigate(`${from}`, { state: { redirectmessage: redirectmessage } });
                            } else {
                                navigate(`/${from}`, { state: { redirectmessage: redirectmessage } });
                            }
                        }
                    } else {
                        navigate('/');
                    }
                })
            }
        } else {
            setAlert({ message: res.message || "Unable to process your request", variant: 'danger' });
        }
    }

    const signInWithGoogle = () => {
        setAlert({})
        setErrors({})
        let provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider)
            .then((result) => {
                let fuser = parseSLUser(result.user.multiFactor.user.auth.currentUser)
                if (fuser) {
                    soclogin(fuser);
                } else {
                    setAlert({ variant: 'danger', message: "Some error Occured! Please try again." })
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    };

    const signInWithApple = () => {
        setAlert({})
        setErrors({})
        let provider = new firebase.auth.OAuthProvider('apple.com');
        auth.signInWithPopup(provider)
            .then((result) => {
                let fuser = parseSLUser(result.user.multiFactor.user.auth.currentUser, provider.providerId)
                if (fuser) {
                    soclogin(fuser);
                } else {
                    setAlert({ variant: 'danger', message: "Some error Occured! Please try again." })
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    };

    const handleSubmit = async (type) => {
        setAlert({})
        setErrors({})

        if (type === 'email') {
            if (!fieldValues.email) {
                setErrors({ email: 'Email is required!' })
                return
            } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(fieldValues.email)) {
                setErrors({ email: 'Enter valid Email Address!' })
                return
            } else {
                setProcessing(true);
                Api.post('user/email/check', {
                    "email": fieldValues.email
                }).then((res) => {
                    if (res.success) {
                        setCont(true);
                    } else {
                        setAlert({ variant: 'danger', message: res?.message + "!" || "Some error Occured! Please try again." })
                    }
                }).catch((res) => {
                    setAlert({ variant: 'danger', message: res?.message || "Some error Occured! Please try again." })

                }).finally(() => {
                    setProcessing(false);
                })
            }
        }

        if (type === 'password') {
            if (!fieldValues.password) {
                setErrors({ password: 'Password is required!' })
                return
            } else {
                const formData = { email: fieldValues.email, password: fieldValues.password };
                setProcessing(true);
                try {
                    let res = await Api.post("auth/login", formData);
                    if (res.success) {
                        dispatch(login({ user: res.data.user, accessToken: res.data.accessToken }));
                        localStorage.setItem('default_model', JSON.stringify(res.data.default_character))
                        Api.setToken(res.data.accessToken).then(() => {
                            if (from?.trim() != "" && from != null) {
                                if (from == 'homepage') {
                                    navigate('/', { state: { redirectmessage: redirectmessage } });
                                } else {
                                    if (from[0] == '/') {
                                        navigate(`${from}`, { state: { redirectmessage: redirectmessage } });
                                    } else {
                                        navigate(`/${from}`, { state: { redirectmessage: redirectmessage } });
                                    }
                                }
                            } else {
                                navigate('/');
                            }
                        })
                    } else {
                        setAlert({ variant: 'danger', message: res?.message || "Some error Occured! Please try again." })
                    }
                } catch (error) {
                    setAlert({ variant: 'danger', message: error?.message || "Some error Occured! Please try again." })
                }
                setProcessing(false);
            }
        }
    }

    useEffect(() => {
        setErrors({})
        setAlert({})
    }, [fieldValues])

    return (
        <div>
            <Helmet>
                <title>{_helmet.title}</title>
                <meta name='description' content={_helmet.des} />
            </Helmet>
            <div className='login'>
                {/* <img src={logo} width={200} /> */}
                <div className={`text-header ${from?.trim() != "" ? " fs-2" : ""}`}>{from?.trim() != "" ? "Login or sign up to continue using Nestheads." : "Welcome Back!"}</div>
                <div style={{ width: '100%' }} >
                    {_alert.message && <Alert dismissible={true} variant={_alert.variant} title='Email error'  >
                        {_alert.message}
                    </Alert>}
                </div>
                {!cont ?
                    <div className='login'>
                        <InputField value={fieldValues.email} placeholder='Enter Email Address' type="email" onChange={onChange} dataKey={'email'} lable='Email Address' />
                        {errors?.email && <div className="required-field" >{errors?.email}</div>}
                        <Button name="Continue" loading={processing} onClick={() => handleSubmit('email')} />
                        <div style={{ display: 'flex', flexDirection: 'row', fontSize: 16, fontWeight: '400', margin: 10 }}>
                            Don't have an Account?
                            <div style={{ marginLeft: 5, fontWeight: '600', cursor: 'pointer' }} onClick={() => navigate('/register', { state: { from, redirectmessage: redirectmessage } })}>
                                Sign up
                            </div>
                        </div>
                        <div style={{ borderTop: '1px solid #92CFD5', width: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '15px' }}>
                            <div style={{ position: 'absolute', fontSize: '16px', lineHeight: "16px", bottom: '-8px', backgroundColor: '#fff', padding: '0px 5px' }}>OR</div>
                        </div>

                        <div className='google-btn' onClick={signInWithGoogle}>
                            <img style={{ marginRight: 10 }} alt='google' src={require('../assets/google.png')} height={23} width={23} />
                            Sign up with Google
                        </div>
                        {/* <img style={{ marginRight: 10 }} src={require('../assets/microsoft.png')} height={23} width={23} /> */}
                        {/* <div className='google-btn-microsoft' >
                            <MicrosoftLogin clientId={YOUR_CLIENT_ID} authCallback={authHandler} className='microsoft-container' children={
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} >
                                    <img style={{ marginRight: 10 }}  alt='microsoft' src={require('../assets/microsoft.png')} height={23} width={23} />
                                    <div style={{ display: 'inline' }}>Continue with Microsoft Account </div>
                                </div>
                            } />
                        </div> */}
                        {/* <AppleLogin clientId="string" redirectURI="https://redirectUrl.com" usePopup={true}
                            render={(renderProps) => {
                                return ( */}
                        {/* <div onClick={renderProps.onClick} className='google-btn'> */}
                        <div onClick={signInWithApple} className='google-btn'>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <img style={{ marginRight: 10 }} alt='apple' src={require('../assets/apple.png')} height={23} width={23} />
                                <div style={{ display: 'inline' }}>Sign up with Apple</div>
                            </div>
                        </div>
                        {/* )
                            }} /> */}
                    </div>
                    :
                    <div className='login'>
                        <InputField value={fieldValues?.password || ''} placeholder='Password' type="password" dataKey="password" onChange={onChange} lable='Password' />
                        {errors?.password && <div className='required-field'>{errors.password}</div>}
                        <div className='forgot-password' onClick={() => navigate('/forgot-password')}>Forgot Password?</div>
                        <Button name="Login" loading={processing} onClick={() => handleSubmit('password')} />
                    </div>}
            </div>
        </div >
    );
};

export default Login;