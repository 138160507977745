const initialState = {
    chats: []
};

export default (state = initialState, action) => {
    switch (action?.type) {

        case 'SET_CHATS':
            return { ...state, chats: action.payload };
        case 'UPDATE_CHATS':
            // console.log(action.payload)
            let hasAlready = state.chats.filter((e) => e.id == action.payload.id)

            if (hasAlready.length > 0) {
                let _updatedChats = [];

                state.chats.map((e) => {
                    if (e.id == action.payload.id) {
                        _updatedChats.push(action.payload)
                    } else {
                        _updatedChats.push(e)
                    }
                })

                return { ...state, chats: _updatedChats };
            } else {
                return { ...state, chats: [...state.chats, action.payload] };
            }

        default:
            return state;
    }
};