import React, { useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';


const Code = () => {

    const navigate = useNavigate();
    const [reset, setReset] = useState(false);
    const [_alert, setAlert] = useState({});
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const [typeC, setTypeC] = useState('cPassword');
    const [iconC, setIconC] = useState(eyeOff);
    const [errors, setErrors] = useState({})
    const [remainingTime, setRemainingTime] = useState(20)
    const [resendOption, setResendOption] = useState(false)

    const data = useLocation()

    const [forgetEmail, setForgetEmail] = useState('')

    const handleToggle = (val) => {
        if (val === 'pass') {
            if (type === 'password') {
                setIcon(eye);
                setType('text')
            } else {
                setIcon(eyeOff)
                setType('password')
            }
        }
        else {
            if (typeC === 'cPassword') {
                setIconC(eye);
                setTypeC('text')
            } else {
                setIconC(eyeOff)
                setTypeC('cPassword')
            }
        }
    }

    const verifyCode = () => {
        if (otp.length == 4) {
            setAlert({ variant: 'danger', message: "Invalid OTP!" });
        } else {
            setAlert({ variant: 'danger', message: "Invalid OTP!" });
        }
    }

    useEffect(() => {
        setAlert({})
        setErrors({})
    }, [otp])

    const resend = () => {

        let time = 20
        let interval = setInterval(() => {
            if (time == 0) {
                clearInterval(interval)
                setResendOption(true)
            } else {
                setRemainingTime(time - 1)
                time = time - 1
            }
        }, 1000)

    }

    const resendCode = () => {
        setRemainingTime(20)
        setResendOption(false)
        resend()
    }

    useEffect(() => {
        resend()
        let email = data?.state?.email || false
        if (email) {
            setForgetEmail(email);
        } else {
            navigate('/forget-password')
        }
    }, [])

    return (
        <div>
            <div className='login'>
                {!reset ?
                    <div className='login'>
                        <div style={{ width: '100%', marginTop: 30 }} >
                            {_alert?.message && <Alert dismissible variant={_alert.variant}>{_alert.message}</Alert>}
                        </div>
                        <div className="text-header">Please Check Your Email Address</div>
                        <div style={{ width: '100%', marginBottom: 30, display: 'flex', justifyContent: 'center', fontSize: 16, alignItems: 'center' }}>
                            We have sent a code to {forgetEmail}.
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <OtpInput
                                inputStyle="inputStyle"
                                numInputs={4}
                                onChange={setOtp}
                                value={otp}
                                renderInput={(props) => <input {...props} />}
                                shouldAutoFocus
                            />
                        </div>
                        <Button name="Verify Code" onClick={verifyCode} />
                        {resendOption ? <div onClick={resendCode}><span style={{ cursor: 'pointer', color: 'blue', textDecoration: "underline" }}>Resend</span> code.</div> : <div style={{ fontSize: 16 }}>Resend Code in 0:{remainingTime} s</div>}
                    </div>
                    :
                    <div className='login'>
                        <div className="text-header" >Reset Password</div>
                        <div className='password-fields'>
                            <span className="password-label">New Password</span>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <input
                                    className='password-input'
                                    type={type}
                                    name="password"
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span className="flex justify-around items-center" onClick={() => { handleToggle('pass') }}>
                                    <Icon className="absolute mr-10" icon={icon} size={25} />
                                </span>
                            </div>
                        </div>

                        <div className='password-fields'>
                            <span className="password-label">Confirm Password</span>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <input
                                    className='password-input'
                                    type={typeC}
                                    name="password"
                                    placeholder="Enter Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <span className="flex justify-around items-center" onClick={() => { handleToggle('cPass') }}>
                                    <Icon className="absolute mr-10" icon={iconC} size={25} />
                                </span>
                            </div>
                        </div>
                        <Button name="Reset Password" />
                    </div>}
            </div>
        </div>
    );
};

export default Code;