const initialState = {
    categories: [],
    titles: []
};

export default (state = initialState, action) => {

    switch (action?.type) {

        case 'SET_CATEGORIES':
            return { ...state, categories: action.payload };
        case 'UPDATE_CATEGORIES':
            let alreadyExists = state.categories.filter((e) => e.id == action.payload.id)
            if (alreadyExists.length > 0) {
                return { ...state };
            } else {
                return { ...state, categories: [...state.categories, action.payload] };
            }
        case "TITLE":
            return { ...state, titles: action.payload };
        case "ADD_NEW_TITLE":
            return { ...state, titles: [...state.titles, action.payload] };

        default:
            return state;
    }
};