const initialState = {
    isLogged: false,
    data: null,
    accessToken: null,
    error: false,
    subscription: null
    // counter: { }
};

export default (state = initialState, action) => {
    let data;//, counter;

    switch (action?.type) {

        case 'ERROR':
            return { ...state, error: action.payload };

        case 'VERIFY':
            return { ...state, ...action.payload };

        case 'LOGIN':

            localStorage.setItem('nestheadToken', action.payload?.accessToken);

            data = {
                isLogged: true,
                data: action.payload?.user,
                accessToken: action.payload?.accessToken,
            };

            return { ...state, ...data };

        case 'UPDATE':
            data = { ...state.data, ...action.payload };
            localStorage.setItem('data', data);
            return { ...state, data: data };

        case 'UPDATE_PROFILE':
            data = {
                data: action.payload?.user,
            };

            return { ...state, ...data };
        case "UPDATE_IMAGE":
            data = {
                data: { ...state.data, photo: action.payload }
            };

            return { ...state, ...data };

        case 'UPDATE_EXTRA':
            let extra = { ...state.data?.extra, ...action.payload };
            data = { ...state.data, extra: extra };
            localStorage.setItem('data', data);
            return { ...state, data: data };

        case 'UPDATE_SETTINGS':
            let settings = { ...state.data?.settings, ...action.payload };
            data = { ...state.data, settings: settings };
            localStorage.setItem('data', data);
            return { ...state, data: data };

        //case 'COUNTER':
        //  counter = { ...state.counter, ...action.payload };
        //return { ...state, counter: counter };

        case 'SUBSCRIPTIONS':
            data = { ...state, subscription: action.payload }
            return data
        case 'DECREMENT_CHATS_SUBSCRIPTIONS':
            data = { ...state, subscription: { ...state.subscription, chats_limit: state.subscription.chats_limit ? state.subscription.chats_limit - 1 : state.subscription.chats_limit = 0 } }
            return data

        case 'DECREMENT_REPORTS_SUBSCRIPTIONS':
            data = { ...state, subscription: { ...state.subscription, reports_limit: state.subscription?.reports_limit ? state.subscription.reports_limit - 1 : state.subscription.reports_limit = 0 } }
            return data

        case 'LOGOUT':

            data = {
                isLogged: false,
                data: null,
                error: false,
                counter: {},
                subscription: null
            }

            localStorage.removeItem('nestheadToken');

            return { ...state, ...data };

        default:
            return state;
    }
};