import React from "react";
import '../App.css'
import { Spinner } from "react-bootstrap";

const Button = (props) => {

    const { name, onClick, loading, variant = 'default' } = props;

    if (variant == 'black') {
        return (
            <div style={{ padding: ' 0px 10px', width: '100%', display: 'flex', justifyContent: 'flex-end', fontFamily: 'Dimbo' }} >
                <div onClick={onClick} style={{ padding: '10px 30px', backgroundColor: 'black', borderRadius: '25px', color: 'white', minWidth: '156px', minHeight: '47px', textAlign: 'center' }}> {loading ? <Spinner size='sm' /> : name}</div>
            </div>
        )
    }

    return (
        <div className="button" style={{ fontFamily: 'Dimbo' }} onClick={onClick}>
            {loading ? <Spinner size="sm" /> : name}
        </div>
    )
}

export default Button;