import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import Icon from "react-icons-kit";
import { arrowUp } from 'react-icons-kit/icomoon/arrowUp'
import { arrowDown } from 'react-icons-kit/icomoon/arrowDown'

const plus = require('../assets/plus-white.png')
const minus = require('../assets/minus-white.png');

const ModelListing = (props) => {

    const { data, onClick, loading, updateLike, onSort, showAdd = true, showSorting = false, type = false } = props;
    const [_loading, setLoading] = useState(true)

    const isLogged = useSelector((state) => state.user.isLogged)
    const selected = useSelector((state) => state.quests.selected)

    const sortModel = (id, data) => {
        onSort(id, data)
    }

    useEffect(() => {
        setLoading(loading)
    }, [loading])

    return (
        <>
            <Helmet>
                <title>Nestheads - models</title>
                <meta name="description" content="models to select" />

                <meta property="og:url" content="https://www.nestheads.ai/models" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="models" />
                <meta property="og:description" content="models to select" />
                {/* <meta property="og:image" content="https://api.nestheads.ai/assets/images/79Mnrepresentativeness%20h%20idle%201.png" /> */}

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="nestheads.ai" />
                <meta property="twitter:url" content="https://www.nestheads.ai/models" />
                <meta name="twitter:title" content="models" />
                <meta name="twitter:description" content="models to select" />

                {/* <meta name="twitter:image" content="https://api.nestheads.ai/assets/images/79Mnrepresentativeness%20h%20idle%201.png" /> */}
            </Helmet>

            {_loading ?
                <>
                    <div style={{ height: '210px', backgroundColor: 'rgb(242, 242, 242)', borderRadius: '15px', width: '100%', margin: '10px 0px' }} ></div>
                    <div style={{ height: '210px', backgroundColor: 'rgb(242, 242, 242)', borderRadius: '15px', width: '100%', margin: '10px 0px' }} ></div>
                    <div style={{ height: '210px', backgroundColor: 'rgb(242, 242, 242)', borderRadius: '15px', width: '100%', margin: '10px 0px' }} ></div>
                </>
                :
                (data.length == 0) ?
                    <div style={{ fontFamily: 'Dimbo', fontSize: 30, textAlign: 'center', margin: '100px 0px' }} >No model available!</div>
                    :
                    (type ? data[0].character : data).map((e, i) => {
                        return (
                            <div key={i} className='character_listing' >
                                <div style={{ display: 'flex', alignItems: 'center' }} >
                                    {(isLogged && showSorting) &&
                                        < div style={{ marginRight: '4px' }}>
                                            <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => sortModel(e.id, "up")} >
                                                <Icon icon={arrowUp} />
                                            </div>
                                            <div style={{ cursor: 'pointer', margin: '4px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => sortModel(e.id, "down")} >
                                                <Icon icon={arrowDown} />
                                            </div>
                                        </div>}
                                    <a className='character' style={{ textDecoration: 'none', color: "#212529" }} href={`/models/${(e?.seo || 'model')}`} onClick={(ele) => onClick(e, ele)} >
                                        <div className="character-image-wrapper"  >
                                            {!e.image ? <div style={{ backgroundColor: "#eee", borderRadius: '50%', width: '100%', height: '100%' }}  ></div>
                                                :
                                                <img src={(type && type == "t") ? e.toxic_photo : e.image} alt={e.name} />
                                            }
                                        </div>
                                        <div>
                                            <h1 className='charater_title' >{e.name} </h1>
                                            <div className='character_des' >{e?.heading} </div>
                                        </div>
                                    </a >
                                </div>
                                {
                                    showAdd &&
                                    <div className={"star-icon " + (selected.find((item) => item.id == e.id) ? "active" : "")} onClick={() => updateLike(e)}  >
                                        <img src={selected.find((item) => item.id == e.id) ? minus : plus} alt={e.liked ? 'plus' : "minus"} />
                                    </div>
                                }
                            </div >
                        )
                    })
            }
        </>
    )
}

export default ModelListing