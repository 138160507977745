import React, { useState, useEffect } from "react";
import InputField from "../components/InputField";
import { Alert, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Api from "../services/Api";
import { login } from "../actions/userAction";
import { useDispatch } from "react-redux";

const CompleteProfile = () => {

    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [loading, setLoading] = useState(false);
    const [_alert, setAlert] = useState({});
    const [errors, setErrors] = useState({});
    const [comurl, setcomurl] = useState('')

    const dispatch = useDispatch()

    const data = useLocation()
    const navigate = useNavigate()

    const updateProfileData = (e) => {
        alert(JSON.stringify(e))
    }

    useEffect(() => {
        setEmail(data?.state?.userData?.email || '');
        setFirstName(data?.state?.userData?.first_name || '');
        setLastName(data?.state?.userData?.last_name || '');
        setRole(1);
        setcomurl(data?.state?.url || "")

    }, [data])

    const checkValidate = () => {

        let error = {}

        if (first_name.trim() == "") {
            error.firstName = "Firstname is required!"
        }
        if (last_name.trim() == "") {
            error.lastName = "Lastname is required!"
        }
        if (email.trim() == "") {
            error.email = "Email is required!"
        } else {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            let valid = reg.test(email)

            if (!valid) {
                error.email = "Enter valid Email Address!"
            }
        }

        if (Object.keys(error).length) {
            setErrors(error)
            return false
        }
        return true
    }
    const compleat_profile = async () => {

        let validate = checkValidate()

        if (validate) {
            setLoading(true)
            let res = await Api.post(comurl, { first_name, last_name, email, emailverify: 1 })
            setLoading(false)
            // console.log(res);
            if (res.success) {
                dispatch(login({ user: res.data.user, accessToken: res.data.accessToken }));
                Api.setToken(res.data.accessToken).then(() => {
                    navigate("/")
                })
            } else {
                setAlert({ variant: 'danger', message: res?.message || "Some error Occured! Please try again." })
            }
        }
    }

    return (
        <div className='settings' style={{marginTop:30}} >
            <div className="text-header"  >Complete Your Profile !</div>
            {_alert?.message && <Alert dismissible variant={_alert.variant}>{_alert.message}</Alert>}
            <div>
                <div className="settings-forms" >
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField value={first_name} placeholder='First name' type="text" onChange={setFirstName} lable='First name' />
                        {errors?.firstName && <div className="required-field" >{errors?.firstName}</div>}
                    </div>
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField value={last_name} placeholder='Last name' type="text" onChange={setLastName} lable='Last name' />
                        {errors?.lastName && <div className="required-field" >{errors?.lastName}</div>}
                    </div>
                </div>
                <div className="settings-forms" >
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField value={email} placeholder='Email Address' type="email" onChange={setEmail} lable='Email' />
                        {errors?.email && <div className="required-field" >{errors?.email}</div>}
                    </div>
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        {/* <InputField disabled={true} value={role ? "User" : 'Admin'} placeholder='Role' type="text" onChange={() => { }} lable='Role' /> */}
                    </div>
                </div>
                <div className='settings-bottom-btn'>
                    {/* <Button loading={loading} name={'Save Changes'} variant={"black"} onChange={updateProfile} /> */}
                    <div style={{ padding: '0px 10px', display: 'flex', justifyContent: 'flex-end', fontFamily: "Dimbo" }} >
                        <div onClick={compleat_profile} style={{ padding: '10px 30px', backgroundColor: 'black', borderRadius: '25px', color: 'white', minWidth: '156px', minHeight: '47px', textAlign: 'center', cursor: 'pointer' }}> {loading ? <Spinner size='sm' /> : "Save"}</div>
                    </div>
                    <div className="settings-btn-cancle" >
                        <div onClick={() => navigate('/')} style={{ padding: '10px 30px', borderRadius: '25px', border: '2px solid black', width: 'fit-content', width: '156px', minHeight: '47px', textAlign: 'center', cursor: 'pointer', fontFamily: "Dimbo" }}>Cancel</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompleteProfile