import React, { useEffect, useState } from "react";
import InputField from "../../components/InputField";
import Api from "../../services/Api";
import { useNavigate } from "react-router-dom";
import { Alert, Spinner } from "react-bootstrap";


const Password = () => {

    const [password, setPassword] = useState('')
    const [newPassword, setNewPAssword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [_alert, setAlert] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})
    const [fieldValues, setFieldVlues] = useState({})


    const navigate = useNavigate();

    useEffect(() => {
        setAlert({})
        setErrors({})
    }, [password, newPassword, confirmNewPassword])

    const checkValidate = () => {

        let error = {}

        if (!fieldValues?.password) {
            error.password = "Password is required!"
        }
        if (!fieldValues?.newPassword) {
            error.newPassword = "New Password is required!"
        }
        if (fieldValues?.newPassword && fieldValues?.newPassword.length < 6) {
            error.newPassword = "New Password is too small!"
        }
        if (!fieldValues?.confirmNewPassword) {
            error.confirmNewPassword = "Confirm Password is required!"
        }
        else if (fieldValues?.newPassword !== fieldValues?.confirmNewPassword) {
            error.confirmNewPassword = "Confirm Password doesn't match!"
        }

        if (Object.keys(error).length) {
            setErrors(error)
            return false
        }
        return true
    }
    const onChange = (key, val) => {

        if (key == 'location') {
            let _vals = { ...fieldValues }
            _vals[key] = val
            setFieldVlues(_vals)
            return
        }
        // console.log(key, val)
        let _vals = { ...fieldValues }
        let _errors = { ...errors }
        _vals[key] = val
        delete _errors[key]
        if (val.trim() == "") {
            delete _vals[key]
        }
        setFieldVlues(_vals)
        setErrors(_errors)
    }

    const handleChange = () => {
        setAlert({})
        let validata = checkValidate()
        if (validata) {
            setLoading(true)
            Api.post("user/update/password", {
                "current_password": fieldValues.password,
                "password": fieldValues.newPassword,
                "confirm_password": fieldValues.confirmNewPassword
            }).then((res) => {
                if (res.success) {
                    // navigate('/chat')
                    setPassword('')
                    setNewPAssword('')
                    setConfirmNewPassword('')
                    setFieldVlues({})
                    setTimeout(() => {
                        setAlert({ variant: 'success', message: "Password updated successfully!" })
                    }, 10)
                } else {
                    setAlert({ variant: 'danger', message: res.message || "Some erroe occured!" })
                }
            }).catch((err) => {
                setAlert({ variant: 'danger', message: err?.message || "Some Error occured please try again!" })

            }).finally(() => {
                setLoading(false)

            })
        }
    }

    return (
        <div>
            {_alert?.message && <Alert dismissible variant={_alert.variant}>{_alert.message}</Alert>}
            <div className='settings-forms' >
                <div style={{ padding: ' 0px 10px', width: '100%' }} >
                    <InputField value={fieldValues?.password || ''} placeholder='Current Password' type="password" onChange={onChange} dataKey={'password'} lable='Current Password' />
                    {errors?.password && <div className="required-field" >{errors?.password}</div>}

                </div>
                <div style={{ padding: ' 0px 10px', width: '100%' }} >
                    <InputField value={fieldValues?.newPassword || ''} placeholder='New Password' type="password" onChange={onChange} dataKey={'newPassword'} lable='New Password' />
                    {errors?.newPassword && <div className="required-field" >{errors?.newPassword}</div>}

                </div>
            </div>
            <div className='settings-forms-single-element' >
                <div >
                    <InputField value={fieldValues?.confirmNewPassword || ''} placeholder='Confirm New Password' type="password" onChange={onChange} dataKey={'confirmNewPassword'} lable='Confirm New Password' />
                    {errors?.confirmNewPassword && <div className="required-field" >{errors?.confirmNewPassword}</div>}
                </div>
            </div>
            <div className='settings-bottom-btn' >
                {/* <Button loading={loading} name={'Save Changes'} variant={"black"} onChange={updateProfile} /> */}
                <div style={{ padding: '0px 10px', display: 'flex', justifyContent: 'flex-end', fontFamily: "Dimbo" }} >
                    <div onClick={handleChange} style={{ padding: '10px 30px', backgroundColor: 'black', borderRadius: '25px', color: 'white', minWidth: '156px', minHeight: '47px', textAlign: 'center', cursor: 'pointer' }}> {loading ? <Spinner size='sm' /> : "Save Changes"}</div>
                </div>
                <div className='settings-btn-cancle' >
                    <div onClick={() => navigate('/')} style={{ padding: '10px 30px', borderRadius: '25px', border: '2px solid black', width: 'fit-content', width: '156px', minHeight: '47px', textAlign: 'center', cursor: 'pointer', fontFamily: "Dimbo" }}>Cancel</div>
                </div>
            </div>
        </div>
    )
}

export default Password