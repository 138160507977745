import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};

const WebcamCapture = (props) => {

    const { openCamera, onClose, onCapture } = props

    const [error, setError] = useState(false)
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            onCapture(imageSrc)
            onClose()
        },
        [webcamRef]
    );

    const onError = () => {
        setError(true)
    }
    const onUserMedia = () => {
        setError(false)
    }

    return (
        <>
            {openCamera && <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', zIndex: 220, backgroundColor: 'rgba(0,0,0,0.9)' }}>
                <div onClick={onClose} style={{ cursor: 'pointer', position: 'absolute', top: 15, right: 15, backgroundColor: '#a0a0a0', width: '30px', height: '30px', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <img src={require('../assets/cross.png')} style={{ width: 8, height: 8 }} />
                </div>
                <Webcam
                    audio={false}
                    height={"70%"}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={"70%"}
                    disablePictureInPicture={true}
                    mirrored={true}
                    onUserMediaError={onError}
                    onUserMedia={onUserMedia}
                    videoConstraints={videoConstraints}
                />
                {error && <div className="text-white">Please provide camera permission or check your camera device!</div>}
                {/* <button onClick={capture}>Capture photo</button> */}
                {!error && <div onClick={capture} style={{ width: 50, height: 50, borderRadius: 25, backgroundColor: 'gray', margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-camera-fill" viewBox="0 0 16 16">
                        <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                        <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                    </svg>
                </div>}
            </div>}
        </>
    );
};

export default WebcamCapture