import React, { useEffect, useState } from "react";

const PrecentageComponent = (props) => {

    const { percent, color = "#92cfd5", height = "8", showPercentage = true, title = "", from = '' } = props;
    const [percentage, setPercentage] = useState(0);
    const [_color, setColor] = useState('rgba(146, 207, 213, 1)');
    const [_height, setHeight] = useState(16);
    let _percent = percentage;
    let inter;

    useEffect(() => {
        setColor(color)
        setHeight(height)

        if (parseInt(percent) < parseInt(percentage)) {
            inter = setInterval(() => {
                if (_percent <= parseInt(percent) || _percent == 0) {
                    clearInterval(inter)
                } else {
                    _percent--
                    setPercentage(_percent)
                }
            }, 10)
        } else {
            inter = setInterval(() => {
                if (_percent >= parseInt(percent) || _percent == 100) {
                    clearInterval(inter)
                } else {
                    _percent++
                    setPercentage(_percent)
                }
            }, 10)
        }
    }, [percent, color, height])

    return (
        <div style={{ display: 'flex', alignItems: 'center' }} >
            <div className={`percentage_controller ${from == "goals" ? "d-none d-md-flex" : "d-flex"}`} style={{ marginRight: showPercentage ? 20 : 0, height: parseInt(_height), borderRadius: parseInt(_height) / 2 }}  >
                <div style={{ height: parseInt(_height), width: parseInt(percentage) + "%", backgroundColor: (from != "goals" && parseInt(percent) > 75) ? "green" : _color, borderRadius: parseInt(_height) / 2, padding: parseInt(percentage) == 0 ? "0px" : "0px 15px", color: 'white', fontSize: '14px', lineHeight: _height + "px", overflow: 'hidden' }}>{(parseInt(percentage) != 0 && parseInt(percentage) <= 75) && title}</div>
            </div>
            {showPercentage && <div style={{ fontSize: 15, minWidth: '40px' }}>{percentage + '%'}</div>}
        </div >
    )
}

export default PrecentageComponent