import React, { useEffect, useRef, useState } from 'react'
import InputField from '../../components/InputField'
import { useSelector } from 'react-redux'
import Api from '../../services/Api';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateProfile, updateImage } from '../../actions/userAction';
import CountryDropDown from '../../components/CountryDropDown';
import axios from 'axios';
import Config from '../../config.json';
import country from '../../country.json'
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';

const Account = () => {

    const [loading, setLoading] = useState(false);
    const [_alert, setAlert] = useState({});
    const [errors, setErrors] = useState({})
    const [resetProcess, setResetProcess] = useState(false)
    const [pictureloading, setPictureLoading] = useState(false)
    const [fieldValues, setFieldVlues] = useState({ gender: 'male' })

    const user = useSelector(state => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        setFieldVlues(user.data)
    }, [user])


    useEffect(() => {
        setAlert({})
        setErrors({})
    }, [fieldValues])

    const onChange = (key, val) => {

        if (key == 'location') {
            let _vals = { ...fieldValues }
            _vals[key] = val
            setFieldVlues(_vals)
            return
        }
        // console.log(key, val)
        let _vals = { ...fieldValues }
        let _errors = { ...errors }
        _vals[key] = val
        delete _errors[key]
        if (val.trim() == "") {
            delete _vals[key]
        }
        setFieldVlues(_vals)
        setErrors(_errors)

    }

    const checkValidate = () => {

        let error = {}

        if (fieldValues.first_name.trim() == "") {
            error.firstName = "FirstName is required!"
        } else if (/\d/.test(fieldValues.first_name)) {
            error.firstName = "FirstName is not valid!"
        }
        if (fieldValues.last_name.trim() == "") {
            error.lastName = "LastName is required!"
        } else if (/\d/.test(fieldValues.last_name)) {
            error.lastName = "Lastname is not valid!"
        }
        if (fieldValues.email.trim() == "") {
            error.email = "Email is required!"
        } else {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            let valid = reg.test(fieldValues.email)

            if (!valid) {
                error.email = "Enter Valid Email!"
            }
        }

        if (Object.keys(error).length) {
            setErrors(error)
            return false
        }
        return true
    }

    const getCountry = (code) => {
        let seccounty = country.filter((e) => e.code == code)
        return seccounty[0]
    }

    const updateProfileData = () => {
        setErrors({})
        setAlert({})
        let validate = checkValidate();

        if (validate) {
            setLoading(true)

            let updatedData = {
                first_name: fieldValues.first_name,
                last_name: fieldValues.last_name,
                email: fieldValues.email,
                user_name: fieldValues.user_name,
                gender: fieldValues.gender,
                city: fieldValues?.city || '',
                location: fieldValues.location,
                date_of_birth: fieldValues.date_of_birth
            }

            Api.post('user/update/account', updatedData).then((res) => {
                if (res.success) {
                    dispatch(updateProfile({ user: res.data.user }))
                    toast.success("Profile updated successfully!")
                } else {
                    if (res.message == 'validtion-errors') {
                        toast.error("Email Already in use!")
                    }
                    else {
                        toast.error(res.message)
                    }
                }
            }).catch((error) => {
                toast.error("Some error occured!")
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const reset = () => {
        setAlert({})
        setResetProcess(true)
        Api.post('user/rest/photo').then((res) => {
            if (res.success) {
                dispatch(updateImage(null))
                setAlert({ variant: 'success', message: "Profile picture updated successfully!" })
            } else {
                setAlert({ variant: 'danger', message: "Profile picture updated failed!" })
            }
        }).catch((err) => {
            setAlert({ variant: 'danger', message: err?.message || "Some Error occured please try again!" })

        }).finally(() => {
            setResetProcess(false)
        })
    }

    const uploadimage = (e) => {
        setAlert({})
        setPictureLoading(false)

        const url = Config.apiUrl + "user/update/photo";
        let file = e.target.files[0]
        let fileSize = file.size / 1024

        if (fileSize.toFixed() <= 800) {

            let token = localStorage.getItem('nestheadToken')
            const formData = new FormData();
            formData.append('photo', file);
            const config = {
                headers: {
                    'Content-type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token,
                }
            };

            setPictureLoading(true)
            axios.post(url, formData, config).then((response) => {
                if (response.data.success) {
                    setAlert({ variant: 'success', message: "Profile picture updated successfully!" })
                    dispatch(updateProfile(response.data.data))
                } else {
                    setAlert({ variant: 'danger', message: "Profile picture updated failed!" })
                }
            }).finally(() => {
                setPictureLoading(false)
            })
        } else {
            setAlert({ variant: 'danger', message: "Image size too Big!" })
        }
    }

    return (
        <div>
            {_alert?.message && <Alert dismissible variant={_alert.variant}>{_alert.message}</Alert>}
            <div className='settings-header' >
                <div className='settings-profile-pic' >
                    <img src={fieldValues.photo ? fieldValues.photo : require('../../assets/user.png')} width='100%' height='100%' alt='Profile picture' />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', margin: '7px 0px' }} >
                    <div id='upload' className='picture-btn' >
                        <div style={{ position: 'relative', backgroundColor: 'black', borderRadius: '30px', color: 'white', padding: '5px 30px', overflow: 'hidden', fontFamily: 'Dimbo' }} >
                            {pictureloading ? <Spinner size='sm' /> : "Upload new Picture"}
                            <input onChange={(e) => uploadimage(e)} type="file" accept="image/gif, image/jpeg, image/png" style={{ position: 'absolute', top: 0, zIndex: 10, left: 0, cursor: 'pointer' }} />
                        </div>
                        <div onClick={fieldValues.photo ? reset : () => { }} className='picture-btn-reset' >{resetProcess ? <Spinner size='sm' /> : "Reset"}</div>
                    </div>
                    <div style={{ fontSize: '12px', color: "rgba(58, 53, 65, 0.68)" }} >
                        Allowed JPG, GIF or PNG. Max size of 800K
                    </div>
                </div>
            </div>
            <div style={{ marginBottom: 20, width: 'fit-content', borderBottom: '2px solid rgb(146, 207, 213)', marginLeft: '15px' }}>
                @<input value={fieldValues.user_name} placeholder='username' style={{ border: 'none', outline: 'none' }} onChange={(e) => onChange('user_name', e.target.value)} />
            </div>
            <div>
                <div className='settings-forms' >
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField value={fieldValues.first_name} placeholder='First name' type="text" dataKey={'first_name'} onChange={onChange} lable='First name' />
                        {errors?.firstName && <div className="required-field" >{errors?.firstName}</div>}
                    </div>
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField value={fieldValues.last_name} placeholder='Last name' type="text" dataKey={'last_name'} onChange={onChange} lable='Last name' />
                        {errors?.lastName && <div className="required-field" >{errors?.lastName}</div>}
                    </div>
                </div>
                <div className='settings-forms' >
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField value={fieldValues.email} placeholder='Email Address' type="email" dataKey={'email'} onChange={onChange} lable='Email' />
                        {errors?.email && <div className="required-field" >{errors?.email}</div>}

                    </div>
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField disabled={true} value={fieldValues.role ? "User" : 'Admin'} placeholder='Role' type="text" onChange={() => { }} lable='Role' />
                    </div>
                </div>
                <div className='settings-forms' >
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        {/* <InputField value={fieldValues.country} placeholder='country' type="dropdown" dataKey={'country'} onChange={onChange} lable='Country' /> */}
                        <CountryDropDown options={country} value={getCountry(fieldValues?.location || "")} onChange={onChange} dataKey={'location'} />
                        {/* {errors?.email && <div className="required-field" >{errors?.country}</div>} */}
                    </div>
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField value={fieldValues.city} placeholder='City' type="text" dataKey={'city'} onChange={onChange} lable='City' />
                    </div>
                </div>
                <div className='settings-forms' >
                    <div style={{ padding: ' 0px 10px', width: '100%' }} >
                        <InputField value={fieldValues.date_of_birth} placeholder='YYYY-MM-DD' type="text" dataKey={'date_of_birth'} onChange={onChange} lable='Date of Birth' />
                    </div>
                    <div style={{ padding: ' 0px 10px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderWidth: 1 }} >
                        <div className="fields" style={{ border: 'none', display: 'flex' }}>
                            <Form.Check
                                style={{ marginRight: 10 }}
                                type={'radio'}
                                label={`Male`}
                                name={'gender'}
                                checked={fieldValues.gender == 'male'}
                                onChange={(e) => onChange("gender", e.target.checked ? "male" : "female")}
                            />
                            <Form.Check
                                type={'radio'}
                                label={`Female`}
                                name={'gender'}
                                checked={fieldValues.gender == 'female'}
                                onChange={(e) => onChange("gender", !e.target.checked ? "male" : "female")}

                            />
                        </div>
                    </div>
                </div>
                <div className='settings-bottom-btn' >
                    {/* <Button loading={loading} name={'Save Changes'} variant={"black"} onChange={updateProfile} /> */}
                    <div style={{ padding: '0px 10px', display: 'flex', justifyContent: 'flex-end', fontFamily: "Dimbo" }} >
                        <div onClick={updateProfileData} style={{ padding: '10px 30px', backgroundColor: 'black', borderRadius: '25px', color: 'white', minWidth: '156px', minHeight: '47px', textAlign: 'center', cursor: 'pointer' }}> {loading ? <Spinner size='sm' /> : "Save Changes"}</div>
                    </div>
                    <div className='settings-btn-cancle' >
                        <div onClick={() => navigate('/')} style={{ padding: '10px 30px', borderRadius: '25px', border: '2px solid black', width: 'fit-content', width: '156px', minHeight: '47px', textAlign: 'center', cursor: 'pointer', fontFamily: "Dimbo" }}>Cancel</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Account