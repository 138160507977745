import React from 'react'
import { Helmet } from 'react-helmet'

const Pagenotfound = () => {
    return (
        <div>
            <Helmet>
                <title>Nestheads / Page not found</title>
                <meta name="description" content="Page not found" />
                {/* Other head tags */}
            </Helmet>
            <div style={{ marginTop: '200px', textAlign: 'center', fontFamily: "Dimbo", fontSize: 30 }} >Page Not Found</div>
        </div>
    )
}

export default Pagenotfound