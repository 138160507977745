import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import Api from '../services/Api';
import { Alert } from 'react-bootstrap';
import InputField from '../components/InputField';
import { useDispatch } from 'react-redux';
import { login } from '../actions/userAction';
import { auth, firebase } from '../firebase'

const Register = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const [alert, setAlert] = useState({ variant: '', message: '' })
    const [loading, setLoading] = useState(false)
    const [fieldValues, setFieldVlues] = useState({})

    const location = useLocation()
    const { from, redirectmessage } = location?.state || { from: "" }

    useEffect(() => {
        setAlert({})
        setErrors({})
    }, [fieldValues])

    const onChange = (key, val) => {

        // console.log(key, val)
        let _vals = { ...fieldValues }
        let _errors = { ...errors }
        _vals[key] = val
        delete _errors[key]
        if (val.trim() == "") {
            delete _vals[key]
        }
        setFieldVlues(_vals)
        setErrors(_errors)

    }



    const checkValidate = () => {

        let error = {}

        if (!fieldValues.firstName) {
            error.firstName = "Firstname is required!"
        }
        if (!fieldValues.lastName) {
            error.lastName = "Lastname is required!"
        }
        if (!fieldValues.email) {
            error.email = "Email is required!"
        } else {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            let valid = reg.test(fieldValues.email)

            if (!valid) {
                error.email = "Enter valid Email Address!"
            }
        }
        if (!fieldValues.password) {
            error.password = "Password is required!"
        } else if (!fieldValues.confirmPassword) {
            error.confirmPassword = "Confirm Password is required!"

        } else if (fieldValues.password !== fieldValues.confirmPassword) {
            error.confirmPassword = "Confirm Password doesn't match!"
        }

        if (Object.keys(error).length) {
            setErrors(error)
            return false
        }
        return true
    }

    const parseSLUser = (user, proID) => {
        var _user = {};
        // console.log(user)
        if (user) {
            var name = (user?.displayName || '').trim();
            var firstName = '', lastName = '';
            if (name.indexOf(' ') !== -1) {
                name = name.split(' ');
                firstName = name[0]; delete name[0];
                lastName = name.join(' ');
            } else {
                firstName = name;
            }

            var email = user?.email || null;
            var phone = user?.phoneNumber || null;
            var pData = user?.providerData && user?.providerData[0] || null;
            if (pData.providerId !== proID && user.providerData.length > 1) {
                user.providerData.map((e) => {
                    if (e.providerId == proID) {
                        pData = e
                        return
                    }
                })
            }
            // console.log(pData?.providerId, pData?.provider, user?.providerId)
            _user = {
                identifier: pData?.uid || user?.uid,
                provider: (pData?.providerId || pData?.provider) || user?.providerId,
                data: {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    phone: phone,
                    photourl: user?.photoURL || null,
                    emailverify: user?.emailVerified ? 1 : 0,
                }
            }
            if (_user.provider)
                _user.provider = _user.provider.toString().replace(".com", '');
        }

        return _user?.identifier ? _user : false;
    };

    const soclogin = async (fuser) => {
        // console.log(fuser.data);
        setLoading(true)
        let res = await Api.post(`auth/loginwith/${fuser.provider}/${fuser.identifier}`, fuser.data);
        setLoading(false)
        if (res.success) {
            if (res.data.action == 'complete_profile') {
                let usr = res.data?.user;
                let url = `auth/completeregistration/${usr?.provider?.name}/${usr?.provider?.identifier}/${usr?.provider?.id}`
                navigate('/complete-profile', { state: { userData: res.data.user, url } })
            } else {
                dispatch(login({ user: res.data.user, accessToken: res.data.accessToken }));
                Api.setToken(res.data.accessToken).then(() => {
                    if (from?.trim() != "") {
                        if (from == 'homepage') {
                            navigate('/', { state: { redirectmessage: redirectmessage } });
                        } else {
                            navigate(`/${from}`, { state: { redirectmessage: redirectmessage } });
                        }
                    } else {
                        navigate('/');
                    }
                })
            }
        } else {
            setAlert({ message: res.message || "Unable to process your request", variant: 'danger' });
        }
    }

    const signInWithGoogle = () => {
        setAlert({})
        setErrors({})
        let provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider)
            .then((result) => {
                let fuser = parseSLUser(result.user.multiFactor.user.auth.currentUser)
                if (fuser) {
                    soclogin(fuser);
                } else {
                    setAlert({ variant: 'danger', message: "Some error Occured! Please try again." })
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    };

    const signInWithApple = () => {
        setAlert({})
        setErrors({})
        let provider = new firebase.auth.OAuthProvider('apple.com');
        auth.signInWithPopup(provider)
            .then((result) => {
                let fuser = parseSLUser(result.user.multiFactor.user.auth.currentUser, provider.providerId)
                if (fuser) {
                    soclogin(fuser);
                } else {
                    setAlert({ variant: 'danger', message: "Some error Occured! Please try again." })
                }
            })
            .catch((error) => {
                console.error(error.message);
            });
    };

    const handleRegister = () => {
        setErrors({})
        setAlert({})
        let validate = checkValidate();

        if (validate) {
            setLoading(true)
            Api.post('auth/register', {
                "first_name": fieldValues.firstName,
                "last_name": fieldValues.lastName,
                "email": fieldValues.email,
                "password": fieldValues.password,
                "confirm_password": fieldValues.confirmPassword,
                // "phone": "9999999999"
            }).then(res => {
                if (res.success) {
                    dispatch(login({ user: res.data.user, accessToken: res.data.accessToken }));
                    // console.log(res.data.default_character)
                    localStorage.setItem('default_model', JSON.stringify(res.data.default_character))
                    Api.setToken(res.data.accessToken);
                    if (from?.trim() != "") {
                        if (from == 'homepage') {
                            navigate('/', { state: { redirectmessage: redirectmessage } });
                        } else {
                            navigate(`/${from}`, { state: { redirectmessage: redirectmessage } });
                        }
                    } else {
                        navigate('/');
                    }
                } else {
                    setAlert({ variant: 'danger', message: res?.message || "some error occured! " })
                }
            }).finally(() => {
                setLoading(false)

            })
        }

    }

    return (
        <div>
            <div className='login'>
                <div className='text-header'>Welcome To Nestheads!</div>
                <div style={{ width: '100%' }} >
                    {alert.message && <Alert dismissible variant={alert.variant} >
                        {alert.message}
                    </Alert>}
                </div>

                <InputField value={fieldValues.firstName} placeholder='Enter Firstname' type="text" dataKey={'firstName'} onChange={onChange} lable='Firstname' />
                {errors?.firstName && <div className="required-field" >{errors?.firstName}</div>}

                <InputField value={fieldValues.lastName} placeholder='Enter Lastname' type="text" dataKey={'lastName'} onChange={onChange} lable='Lastname' />
                {errors?.lastName && <div className="required-field" >{errors?.lastName}</div>}

                <InputField value={fieldValues.email} placeholder='Enter Email Address' type="text" dataKey={'email'} onChange={onChange} lable='Email Address' />
                {errors?.email && <div className="required-field" >{errors?.email}</div>}

                <InputField value={fieldValues.password} placeholder='Enter Password' type="password" dataKey={'password'} onChange={onChange} lable='Password' />
                {errors?.password && <div className="required-field" >{errors?.password}</div>}

                <InputField value={fieldValues.confirmPassword} placeholder='Enter Confirm Password' dataKey={'confirmPassword'} type="password" onChange={onChange} lable='Confirm Password' />
                {errors?.confirmPassword && <div className="required-field" >{errors?.confirmPassword}</div>}

                <Button name="Continue" loading={loading} onClick={handleRegister} />

                <div style={{ borderTop: '1px solid #92CFD5', width: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '15px' }}>
                    <div style={{ position: 'absolute', fontSize: '16px', lineHeight: "16px", bottom: '-8px', backgroundColor: '#fff', padding: '0px 5px' }}>OR</div>
                </div>

                <div className='google-btn' onClick={signInWithGoogle}>
                    <img style={{ marginRight: 10 }} alt='google' src={require('../assets/google.png')} height={23} width={23} />
                    Sign up with Google
                </div>
                {/* <img style={{ marginRight: 10 }} src={require('../assets/microsoft.png')} height={23} width={23} /> */}
                {/* <div className='google-btn-microsoft' >
                            <MicrosoftLogin clientId={YOUR_CLIENT_ID} authCallback={authHandler} className='microsoft-container' children={
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} >
                                    <img style={{ marginRight: 10 }}  alt='microsoft' src={require('../assets/microsoft.png')} height={23} width={23} />
                                    <div style={{ display: 'inline' }}>Continue with Microsoft Account </div>
                                </div>
                            } />
                        </div> */}
                {/* <AppleLogin clientId="string" redirectURI="https://redirectUrl.com" usePopup={true}
                            render={(renderProps) => {
                                return ( */}
                {/* <div onClick={renderProps.onClick} className='google-btn'> */}
                <div onClick={signInWithApple} className='google-btn'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <img style={{ marginRight: 10 }} alt='apple' src={require('../assets/apple.png')} height={23} width={23} />
                        <div style={{ display: 'inline' }}>Sign up with Apple</div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Register;