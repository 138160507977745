import React, { useEffect, useState } from 'react'
import Buttons from "./Buttons";
import AddHabit from "./AddHabit";
import Api from '../services/Api';
import SubCategoryLoadingComponent from './SubCategoryLoadingComponent';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { setTitles } from '../actions/categoriesActions';
import { Helmet } from 'react-helmet';
import ModelListing from './ModelListing';
import { useNavigate } from 'react-router-dom';
import ToggleToxicHealthy from './ToggleToxicHealthy';

const SubCategory = (props) => {

    const { categoryTitle } = props
    const [showPopup, setShowPopup] = useState(false);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleating, setDeleating] = useState(null)
    const categories = useSelector(state => state.category.categories)
    const isLogged = useSelector(state => state.user.isLogged)
    const [selectedFilter, setSelectedFilter] = useState('new');
    const [activetype, setActiveType] = useState('h');
    // const titles = useSelector(state => state.category.titles)
    const navigate = useNavigate()

    const titles = [];
    const [_helmet, sethelmet] = useState({ title: "", des: '' });
    const dispatch = useDispatch();

    useEffect(() => {
        if (parseInt(categoryTitle)) {

            sethelmet({ title: "Nestheads - " + categoryTitle.split("-")[1] });
            setLoading(true);
            Api.get('user-category/detail/' + parseInt(categoryTitle))
                .then((res) => {
                    if (res.success) {
                        setData(res.data)
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [categoryTitle])

    const onConfirm = (res) => {
        // console.log(res)
        setShowPopup(false)
        if (!res) {
            return
        } else {
            let character_id = categories.filter((e) => e.name.toLowerCase() == categoryTitle.toLowerCase())
            // console.log(character_id)

            let datas = {
                "category_id": character_id[0].id,
                "category_name": categoryTitle,
                "chat_title": res
            }
            Api.post("title/add", datas)
                .then((res) => {
                    if (res.success) {
                        let newData = [...titles, res.data]
                        dispatch(setTitles(newData))
                        toast.success("New " + categoryTitle + " created!")
                    } else {
                        toast.error(res.message || "Some error occurred!")
                    }
                })
                .catch((err) => {

                })
                .finally(() => {

                })
        }
    }

    const handleDelete = (id) => {
        setDeleating(id)
        Api.post("title/delete/" + id)
            .then((res) => {
                if (res.success) {
                    let _data = [...data]
                    let _updated = _data.filter((e) => e.id != id)
                    setData(_updated)
                } else {
                    toast.error(res.message || "Some error Occurred!")
                }
            })
            .catch((err) => {
            })
            .finally(() => {
                setDeleating(null)
            })
    }

    const details = (e, ele) => {
        ele.preventDefault();
        if (isLogged) {
            navigate(`/models/${(e?.seo || 'model')}`)
        } else {
            navigate((e?.seo || 'model'))
        }
    }

    const onSort = (id, sort) => {

        let _data = [...data[0].character]
        let index = _data.findIndex(item => item.id == id)

        if ((index == 0 && sort == "up") || (index == (_data.length - 1) && sort == 'down')) {
            toast.error(`No item is ${sort == "down" ? "below" : "above"}!`)
            return
        }

        Api.post("user-category/" + parseInt(categoryTitle) + "/item/re-order",
            {
                "character_id": id,
                "direction": sort
            })
            .then((res) => {
                if (res.success) {
                    if (sort == "up") {
                        let before = _data[index]
                        _data[index] = _data[index - 1]
                        _data[index - 1] = before
                    } else {
                        let before = _data[index]

                        _data[index] = _data[index + 1]
                        _data[index + 1] = before
                    }
                    let updatedData = [...data]
                    updatedData[0].character = _data

                    setData(updatedData)
                } else {
                    toast.error(res?.message || "Some Error occured!")
                }
            })
            .catch((err) => {
                toast.error(err?.message || "Some Error occured!")
            })
    }

    const onFilterChange = (e) => {
        setSelectedFilter(e.target.value)
    }

    const onToggleChange = (val) => {
        setActiveType(val)
    }

    return (
        <div style={{ width: '90%', margin: 'auto', marginTop: '100px' }} >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }} >
                <div style={{ flex: 1 }} ></div>
                <div style={{ flex: 1 }} >
                    <ToggleToxicHealthy activetype={activetype} onChange={onToggleChange} />
                </div>
                <div style={{ flex: 1, alignSelf: 'flex-end' }} ><div className='charactor_filter_container' >
                    {/* <div className='my-4 mx-2 text-right'>
                        <select className='character_filter' value={selectedFilter} style={{ fontFamily: 'Dimbo' }} title='Filter' onChange={onFilterChange}>
                            <option className='filter_options' value={'new'}>newest</option>
                            <option className='filter_options' value={'name'} >name</option>
                            <option className='filter_options' value={'popularity'}>popularity</option>
                        </select>
                    </div> */}
                </div></div>
            </div>
            <ModelListing data={data} loading={loading} showAdd={false} onClick={details} onSort={onSort} type={activetype} showSorting={true} />
        </div>
    )
}

export default SubCategory