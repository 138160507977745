const initialState = {
    charactor: null,
    default_charactor: null
};

export default (state = initialState, action) => {

    switch (action?.type) {

        case 'SET_CHARACTOR':
            return { ...state, charactor: action.payload };
        case 'UPDATE_CHARACTOR':
            return { ...state, charactor: [...state.charactor, action.payload] };
        case 'DEFAULT_CHARACTOR':
            // console.log(action.payload)
            return { ...state, default_charactor: action.payload };

        default:
            return state;
    }
};