import React, { useState, onSubmit, useRef } from "react";

const BlinkingInputField = (props) => {

    const { val = 'Nest Title', setVal = null, placeholder } = props;

    const [isInputFieldActive, setInputFieldActive] = useState(false);
    // const [val, setVal] = useState();

    const ref = useRef()

    const onValChange = (e) => {
        setVal(e.target.value)
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (val.trim() == "") {
            setVal(placeholder);
        }
        setInputFieldActive(false)
    }
    const onBlur = () => {
        if (val.trim() == "") {
            setVal(placeholder);
        }
        setInputFieldActive(false)
    }

    const onTextClick = () => {
        setInputFieldActive(true)
        setTimeout(() => {
            ref.current.focus();
        }, 100)

    }

    return (
        <>
            {
                !isInputFieldActive ?
                    <div style={{ textAlign: 'center' }} onClick={onTextClick}  >
                        <h2 style={{ width: 'fit-content', display: 'inline-block', margin: 'auto', fontFamily: 'Dimbo' }} >{val}
                            <div style={{ display: 'inline-block' }} className="blinking-input"> </div>
                        </h2>
                    </div>
                    :
                    <form onSubmit={onSubmit} style={{ width: '100%' }}>
                        <input placeholder={placeholder} ref={ref} onBlur={onBlur} onChange={onValChange} value={val} style={{ width: '100%', fontSize: '21px' }} />
                    </form>
            }
        </>
    )
}

export default BlinkingInputField
