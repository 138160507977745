import React, { useState } from "react";

const ImageView = (props) => {

    const { image, onClose = () => alert("closing"), showImageView } = props

    return (
        <div>
            {showImageView &&
                < div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0, 0, 0, 0.9)',
                    zIndex: 102,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }} >
                    <div onClick={onClose} style={{ cursor: 'pointer', position: 'absolute', top: 15, right: 15, backgroundColor: '#a0a0a0', width: '30px', height: '30px', borderRadius: '5px', display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <img src={require('../assets/cross.png')} style={{ width: 8, height: 8 }} />
                    </div>
                    <div style={{ width: '70%', height: '70%' }} >
                        <img src={image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                        {/* <div>Test</div> */}
                    </div>
                </div>
            }
        </div >
    )
}
export default ImageView