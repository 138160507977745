import React, { useEffect, useState, useRef } from 'react'
import country from '../country.json'

const CountryDropDown = ({ onChange, value, options, dataKey }) => {

    const [selectedVal, setSelectedVal] = useState(value)
    const [searchVal, setSearchVal] = useState('')
    const [filteredVals, setFilteredVals] = useState([])
    const [showDropDown, setShowDropDown] = useState(false)

    const contentref = useRef(null)

    useEffect(() => {
        if (selectedVal) {
            onChange(dataKey, selectedVal.code)
        } else {
            onChange(dataKey, value?.code)
        }
    }, [selectedVal])

    useEffect(() => {
        if (searchVal?.length > 2) {
            let filteredData = options.filter((e) => {
                return e.name.toLowerCase().includes(searchVal.toLowerCase())
            })
            setFilteredVals(filteredData)
        } else {
            setFilteredVals(options)
        }
    }, [searchVal])

    useEffect(() => {
        setSearchVal(value?.name || '')
    }, [value])

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (contentref.current && !contentref.current.contains(event.target)) {
                setShowDropDown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="fields">
            <span className="inputlabel" style={{ zIndex: 10 }} >{"Country"}</span>
            <div style={{ display: 'flex', marginLeft: 25, alignItems: 'center' }}>
                {value?.image ? <img src={value.image} width={30} style={{ display: 'inline' }} />
                    : <div style={{ width: 30, height: 20, backgroundColor: '#ccc', display: 'inline' }} ></div>
                }
                <input
                    defaultValue={value?.name || ''}
                    value={searchVal}
                    className="input-field"
                    placeholder="Select your country"
                    onChange={(e) => {
                        setShowDropDown(true)
                        setSearchVal(e.target.value)
                    }}
                />
            </div>
            {showDropDown && <div ref={contentref} style={{ maxHeight: 200, position: 'absolute', marginTop: 5, backgroundColor: '#fff', border: "1px solid #ccc", borderRadius: 10, overflow: 'scroll', zIndex: 120 }}  >
                {filteredVals.length ?
                    filteredVals.map((e, i) =>
                        <div key={i} className='dropdown-items' onClick={() => { setShowDropDown(false); setSelectedVal(e) }}> {e?.image && <img src={e.image} alt='img' style={{ width: '30px', marginRight: 20 }} />}{e.name}</div>)
                    : <div className='dropdown-items'>Not Found</div>}

            </div>}
        </div>
    )
}

export default CountryDropDown