import React, { useEffect, useState } from "react";
import Buttons from "../components/Buttons";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Api from "../services/Api";
import PlanExpired from "../components/PlanExpired";
import CustomModel from "../components/CustomModel";
import { Elements } from "@stripe/react-stripe-js";
import PaymentModel from "../components/PaymentModel";
import { loadStripe } from "@stripe/stripe-js";
import { Alert, Spinner } from "react-bootstrap";
import { subscriotions } from "../actions/userAction";

export const SubscriptionForAll = () => {

    const [loading, setLoading] = useState(false)
    const [intentloading, setIntentLoading] = useState(null)
    const [planType, setPlanType] = useState('m')
    const [_alert, setAlert] = useState({ message: "", variant: "" })
    const [plans, setPlans] = useState([])
    const [secret, setSecret] = useState(false)
    const [showPaymentModel, setShowPaymentModel] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState({})
    const [showPlanExpiredModel, setShowPlanExpiredModel] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [modelData, setModelData] = useState({})
    const [subscribed, setSubscribed] = useState(null)
    const [checkoutLoading, setCheckoutLoading] = useState(false)
    const [maxDis, setMaxDis] = useState({ monthly: '', yearly: '' })

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const activeSubscription = useSelector(state => state.user.subscription);

    let opp = false;
    let planExpired = false;

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    let { from = null, redirectmessage = null } = location?.state || {};

    const checkout = async (props) => {
        setShowPaymentModel(false)
        setCheckoutLoading(true);
        if (props.success) {
            let chargeData = await Api.post('subscription/payment/charges', {
                "paymentIntentId": props?.data?.id
            })

            let data

            if (props?.from == 'redirect') {
                data = { ...props.data, transaction_id: chargeData?.data?.data.id || "" }
            } else {
                data = {
                    amount: props.data.amount,
                    subscription_id: selectedPlan.id,
                    transaction_id: chargeData?.data?.data.id || "",
                    stripe_sub_id: secret.stripe_sub_id
                }
            }
            Api.post('subscription/purchase', data)
                .then((res) => {
                    if (res.success) {
                        setAlert({ message: 'Payment successful!', variant: 'success' });
                        setSubscribed(res.data);
                        dispatch(subscriotions(res.data));

                        if (from == 'homepage') {
                            navigate('/', { state: { redirectmessage: redirectmessage || '' } })
                        } else if (from?.trim() != '' && from != null) {
                            navigate(`${from}`, { state: { redirectmessage: redirectmessage || '' } })
                        } else {
                            navigate(`/`, { state: { redirectmessage: redirectmessage || '' } })
                        }

                    } else {
                        setAlert({ message: 'Some error occurred!', variant: 'danger' });
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                        setCheckoutLoading(false);
                    }, 100)
                })
        } else if (parseInt(props?.data?.price) == 0) {

            let data = {
                amount: '0',
                subscription_id: selectedPlan.id,
                transaction_id: props?.data?.id || "free",
                stripe_sub_id: secret.stripe_sub_id || 'free'
            }
            setCheckoutLoading(true)

            Api.post('subscription/purchase', data)
                .then((res) => {
                    if (res.success) {
                        setAlert({ message: 'Payment successfull!', variant: 'success' });
                        setSubscribed(res.data);
                    } else {
                        setAlert({ message: 'Some error occured!', variant: 'danger' });
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                        setCheckoutLoading(false);
                    }, 100)
                })
        } else {
            setAlert({ message: props?.msg || 'Payment failed!', variant: 'danger' })
            setShowPaymentModel(false)
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 100)
        }


    }
    const handleActivate = (e) => {

        // console.log(subscribed)
        // console.log(e)

        if (subscribed?.subscription_id == e.id && subscribed.plan_type == e.period) {
            return
        } else {
            setSelectedPlan(e);
            activate(e)
        }
    }

    const activate = (e, type) => {
        if (e.price == "free" || parseInt(e.price) == "0") {
            let data = { data: e }
            checkout(data)
            setShowPlanExpiredModel(false)
            window.scroll(0, 0)
            return
        }
        // setSelectedPlan(e);
        setShowPlanExpiredModel(false)
        setIntentLoading(e.id)
        let clientToken = false;
        Api.post('subscription/payment/intent',
            {
                "subscription_id": e.id,
                "currency": "usd",
            })
            .then((res) => {
                if (res.success) {
                    if (res.data.data.client_secret == null) {
                        activate(e)
                        return
                    }
                    let details = {
                        plan_type: planType,
                        amount: e.price,
                        subscription_id: e.id,
                        stripe_sub_id: res.data.data.stripe_sub_id
                    }
                    clientToken = true
                    localStorage.setItem('payment_des', JSON.stringify(details))
                    setSecret({ stripe_sub_id: res.data.data.stripe_sub_id, clientSecret: res.data.data.client_secret })
                    setShowPaymentModel(true)
                } else {
                    setAlert({ message: res.error.message || 'some unknown error occured!', variant: 'danger' })
                    setIntentLoading(null)
                }
            })
            .catch((res) => {
                setAlert({ message: 'Some unknown error occurred. Please try later!', variant: 'danger' })
                setIntentLoading(null)
            })
            .finally(() => clientToken ? setIntentLoading(null) : {})
    }

    const oppRes = (accepted) => {
        if (accepted) {
            opp = false
            setShowPopup(false)
            // console.log('accepted')
        } else {
            setShowPopup(false)
            opp = false
            // console.log('declined')
        }
    }

    useEffect(() => {
        if (activeSubscription) {
            setSubscribed(activeSubscription)
        }
    }, [activeSubscription])

    useEffect(() => {
        setLoading(true)
        setAlert({ message: "", variant: "" })

        Api.post('subscription/list')
            .then((res) => {
                if (res.success) {

                    let maxMonthly = 0
                    let maxYearly = 0

                    res.data.map((e) => {
                        if (e.period == 'monthly') {
                            if (parseInt(e.discount) > maxMonthly) {
                                maxMonthly = parseInt(e.discount)
                            }
                        }
                        else if (e.period == 'yearly') {
                            if (parseInt(e.discount) > maxYearly) {
                                maxYearly = parseInt(e.discount)
                            }
                        }
                    })

                    setMaxDis({ monthly: maxMonthly, yearly: maxYearly });
                    let sorted_array = res.data.sort((a, b) => a.price - b.price)
                    setPlans(sorted_array)
                }
            })
            .finally(() => [
                setLoading(false)
            ])

        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.has('redirect_status')) {
            if (searchParams.get('redirect_status') == 'succeeded') {
                let payment_intent = searchParams.get('payment_intent')
                let client_secret = searchParams.get('payment_intent_client_secret')
                // console.log(payment_intent, client_secret)
                let data = localStorage.getItem('payment_des')
                let parsedData = JSON.parse(data)
                parsedData['transaction_id'] = payment_intent
                let payment = { success: true, data: parsedData, from: 'redirect' }
                checkout(payment)
            } else {
                setAlert({ message: "Error Occurred!", variant: "danger" })
            }
        } else {

            // if (location?.state?.plan) {
            //     activate(location.state.plan, location.state.plan_type)
            // } else {
            if (opp) {
                setModelData({ title: "Opportunity for you!", description: 'Do you want to avail opportunity for 2 free months to stay a member?', onClick: oppRes })
                setShowPopup(true)
            }

            if (planExpired) {
                setShowPlanExpiredModel(true)
            }

            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 100)
        }
    }, [])

    const buttonText = (subscribed, plan) => {
        if (!subscribed) {
            return "Activate"
        }
        let _planType = planType == 'm' ? 'monthly' : 'yearly'
        let subscribedPlantype = subscribed.subscription
        if (_planType == subscribedPlantype.period) {
            if (subscribed.subscription_id == plan.id) {
                return "Current"
            }
            // console.log(_planType, subscribed.subscription.period)
            if (subscribedPlantype.original_price < plan.original_price) {
                return "Upgrade"
            } else if (subscribedPlantype.original_price > plan.original_price) {
                return "Downgrade"
            }
        } else if (subscribedPlantype.period == "monthly" && _planType == "yearly") {
            return "Upgrade"
        } else if (subscribedPlantype.period == "yearly" && _planType == "monthly") {
            return "Downgrade"
        }
        // return "text"
    }

    return (
        <div className="page_container" >
            {checkoutLoading && <div style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(0, 0, 0, 0.8)", zIndex: 110, color: "white" }}><Spinner /></div>}
            <h1 style={{ fontFamily: 'Dimbo', fontSize: '35px', margin: "120px  0px 20px", textAlign: 'center' }}>{from !== null ? "Please subscribe to continue using Nestheads." : "Subscription Plans"}</h1>
            <div style={{ textAlign: 'center', width: 'fit-content', margin: 'auto', display: 'flex', marginBottom: '20px' }} >
                <div style={{ borderBottom: planType == 'm' ? '3px solid rgba(146, 207, 213, 1)' : 'none', color: planType == 'm' ? 'black' : "#888", margin: "0 20px", cursor: 'pointer', fontSize: '20px', fontWeight: '600px' }} onClick={() => setPlanType('m')}  >Monthly{maxDis.monthly && `(${maxDis.monthly}%)`}</div>
                <div style={{ borderBottom: planType == 'a' ? '3px solid rgba(146, 207, 213, 1)' : 'none', color: planType == 'a' ? 'black' : "#888", margin: "0 20px", cursor: 'pointer', fontSize: '20px', fontWeight: '600px' }} onClick={() => setPlanType('a')} >Annual {maxDis.yearly && `(${maxDis.yearly}%)`}</div>
            </div>
            {_alert?.message.trim() !== '' && <Alert variant={_alert.variant} dismissible onClose={() => setAlert({ message: '', variant: '' })} >{_alert.message}</Alert>}
            <PlanExpired showPopup={showPlanExpiredModel} setShowPopup={setShowPlanExpiredModel} activate={activate} />
            <CustomModel showPopup={showPopup} setShowPopup={setShowPopup} title={modelData.title} description={modelData.description} onConfirm={modelData.onClick} />
            {showPaymentModel &&
                <Elements stripe={stripePromise} options={{ clientSecret: secret.clientSecret }}>
                    <PaymentModel showPopup={showPaymentModel} setShowPopup={setShowPaymentModel} onConfirm={checkout} clientSecret={secret.clientSecret} />
                </Elements>}
            <div className="subscriptions_page_plans">
                {loading &&
                    <>
                        <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '500px', width: '100%', margin: '5px 0px' }} ></div>
                        <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '500px', width: '100%', margin: '5px 0px' }} ></div>
                        <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '500px', width: '100%', margin: '5px 0px' }} ></div>
                    </>
                }
                {!loading && plans.map((e, i) => {
                    let _planType = planType == 'm' ? 'monthly' : 'yearly'
                    // console.log(e.period + ' ' + _planType)
                    if (e.period != _planType) {
                        return
                    }
                    return (
                        <div key={i} style={{ display: 'flex', flexDirection: 'column', position: 'relative', marginBottom: '10px' }}  >
                            <div style={{ flex: '1', borderRadius: '10px', border: '1px solid rgb(146, 207, 213)', backgroundColor: '#fff', overflow: 'hidden', marginTop: !e.recommended ? '35px' : '0px' }}>
                                {e.recommended &&
                                    <div style={{ backgroundColor: "rgb(146, 207, 213)", textAlign: 'center', fontWeight: '800', height: '35px', lineHeight: "35px", color: '#fff', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={require('../assets/plans-star.png')} height={'20px'} alt='star recommended' style={{ objectFit: 'contain', marginRight: '5px' }} />
                                        Recommended
                                    </div>
                                }
                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100% ', paddingBottom: e.recommended ? "35px" : '0' }}>
                                    <div style={{ padding: '20px 25px' }}  >
                                        <div style={{ fontSize: '20px', fontWeight: '800' }} >{e.name}</div>
                                        <div style={{ fontSize: '15px', color: "rgba(0 , 0 , 0 , 0.6)" }} >{e.description}</div>
                                        <div>
                                            <div style={{ margin: '15px 0' }} >
                                                {/* <div style={{ fontFamily: "Dimbo", fontSize: '35px', lineHeight: '35px' }} >{!parseInt(price) ? "Free" : price}<span style={{ fontSize: '20px' }}>{parseInt(price) ? (planType == 'a' ? "/yearly" : '/month') : ''}</span></div> */}
                                                {/* <div style={{ fontFamily: "Dimbo", fontSize: '35px', lineHeight: '35px' }} >{planType == 'm' ? e.price : (e.price * 12) - ((e.price * 12) * (25 / 100))}<span style={{ fontSize: '20px' }}>{parseInt(e.price) ? (planType == 'a' ? "/yearly" : '/month') : ''}</span></div> */}
                                                <div style={{ fontFamily: "Dimbo", fontSize: '35px', lineHeight: '35px' }} >{parseInt(e.price) == 0 ? "Free" : e.price}<span style={{ fontSize: '20px' }}>{planType == 'a' ? "/yearly" : '/monthly'}{e?.discount && `(-${parseInt(e.discount) + "%)"}`}</span></div>
                                                {e.free_trial && e.no_of_trial_days && <div style={{ color: "rgba(0 , 0 , 0 , 0.6)", fontSize: '15px', lineHeight: '15px' }}  >{`Billed monthly after ${e.no_of_trial_days} day trial`}</div>}
                                            </div>
                                            {e?.features && e.features.map((ele, i) => {
                                                return (
                                                    <div key={i} style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                                                        <span style={{ color: '#92cfd5', marginTop: 4 }} ><img src={require('../assets/plans-check.png')} alt='plans check' /></span>
                                                        <span style={{ marginLeft: '10px', lineHeight: '20px', fontSize: '15px', margin: 7, color: "rgba(0 , 0 , 0 , 0.6)" }}>{ele}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div style={{ margin: 20 }} >
                                        {/* {subscribed?.subscription_id + ' ' + e.id} */}
                                        <Buttons title={buttonText(subscribed, e)} onClick={() => handleActivate(e)} variant='default' loading={intentloading == e.id} />
                                        {/* <Buttons title={subscribed?.subscription_id == e.id ? "Current" : (subscribed?.subscription?.plan_type == "monthly" && _planType == 'yearly') ? "Upgrade" : (subscribed?.subscription?.plan_type == "yearly" && _planType == 'monthly') ? "downgrade" : (subscribed?.subscription?.plan_type == _planType) ? (subscribed?.subscription?.original_price > e.original_price) ? "Downgrade" : "Upgrade" : 'Downgrade'} onClick={() => handleActivate(e)} variant='default' loading={intentloading == e.id} /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}