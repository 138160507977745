import React, { useState, useEffect, useRef } from 'react'
import Buttons from './Buttons';
import { Spinner } from 'react-bootstrap';

const CustomModel = (props) => {

    const { loading = false, showPopup, setShowPopup, title = "Title", description = "Description", onConfirm, image = null, varient = "default", color } = props

    const popupRef = useRef(null);
    const contentref = useRef(null)


    useEffect(() => {
        // console.log(color)
        const handleClickOutside = (event) => {
            if (contentref.current && contentref.current.contains(event.target)) {
            } else {
                if (popupRef.current && popupRef.current.contains(event.target)) {
                    setShowPopup(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            {showPopup && (
                <div
                    ref={popupRef}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'rgba(0, 0, 0, 0.8)',
                        zIndex: 102,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div ref={contentref} className={`${varient == 'default' ? "custom_model" : 'custom_model_quest'}`}>
                        {varient == "quest" &&
                            <div style={{ maxHeight: "115px" }}>
                                {image ?
                                    <img src={image} style={{ maxHeight: '160px', objectFit: 'contain' }} /> :
                                    <img src={require('../assets/Character/Conscience.png')} style={{ maxHeight: '160px', objectFit: 'contain' }} />}
                            </div>
                        }
                        <div style={{ border: varient == "quest" ? '2px solid black' : 'none', borderRadius: "35px", padding: varient == "quest" ? '20px 30px' : '0', zIndex: 10, position: 'relative', backgroundColor: '#fff' }} >
                            <div style={{ fontSize: varient == "quest" ? "24px" : '40px', fontFamily: 'Dimbo', textAlign: varient == "quest" ? 'left' : 'center', color: color || 'rgb(248, 123, 83)' }} >{varient == "quest" ? title + ":" : title}</div>
                            <div style={{ textAlign: varient == "quest" ? 'left' : 'center', fontFamily: varient == "quest" ? "Dimbo" : "initial", fontSize: varient == "quest" ? '22px' : "initial", lineHeight: varient == "quest" ? '26px' : "initial" }}>{description}</div>
                        </div>
                        <div style={{ width: varient == "quest" ? "80%" : '100%', display: 'flex', alignItems: 'center', margin: '0px auto', marginTop: '30px' }} >
                            <div style={{ flex: 1, margin: '0 5px' }}>
                                <Buttons title={'No'} variant='transparent' onClick={() => onConfirm(false)} />
                            </div>
                            <div style={{ flex: 1, margin: '0 5px' }}>
                                <Buttons title={!loading ? 'Yes' : <Spinner size='sm' />} onClick={() => onConfirm(true)} />
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    )
}

export default CustomModel