import React from 'react'
import Icon from "react-icons-kit";
import { arrowLeft } from 'react-icons-kit/icomoon/arrowLeft'
import { arrowRight } from 'react-icons-kit/icomoon/arrowRight'
const Switch = (props) => {

    const { isOn, setSwitch, outterStyle, innerStyle, autoplay = false } = props

    return (
        <div className='switch_outer' style={outterStyle}>
            <div className={`switch_inner ${isOn == null ? "" : isOn ? "switch_on" : 'switch_off'}`} style={innerStyle} onClick={() => setSwitch(!isOn)} >
                {autoplay && <div style={{ backgroundColor: '#fff', position: 'absolute', width: '30px', height: "30px", top: "-6px", left: "-6px", color: '#fff', borderRadius: "50%", display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    < img src={require('../assets/play-button-arrowhead.png')} style={{ width: "12px", height: '12px', objectFit: 'contain' }} />
                </div>}
            </div>
        </div>
    )
}

export default Switch